import { FC, MouseEvent, useEffect, useState } from "react";
import { URLConstants } from "@/constants/URLConstants";
import { useAppContext } from "@/context/AppContextProvider";
import { usePathname, useRouter } from "next/navigation";
import useEcho from "@/hooks/useEcho";
import { NotficationEventResponse } from "@/restapi/notification";
import Link from "next/link";
import HomeActiveIcon from "@/components/icons/HomeActiveIcon";
import HomeDeactiveIcon from "@/components/icons/HomeDeactiveIcon";

const HomeLink: FC = () => {
  const pathname = usePathname();
  const router = useRouter();
  const [isHomeHovered, setIsHomeHovered] = useState(false);
  const { setSettingMenuProps, isMobile, isModalOpen, setScreenType, screenType } =
    useAppContext();

  const handleClick = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>, url: string, callback?: () => void,) => {
    e.preventDefault();
    e.stopPropagation();

    setSettingMenuProps({
      isMeneuOpen: false,
    });

    if (typeof window !== 'undefined') {
      window.location.href = url;
    }

    // callback && callback();
  };


  // // 新着投稿通知件数リアルタイム反映処理
  // const echo = useEcho();
  // useEffect(() => {
  //   if (echo) {
  //     echo
  //       .channel("channel-notification-new-post")
  //       .listen(
  //         "NotificationNewPostEvent",
  //         (response: NotficationEventResponse) => {
  //           if (response.notifications && 0 < response.notifications.length) {
  //             response.notifications.forEach((n) => {
  //               if (n.userUuid === userId) {
  //                 setCount(n.count);
  //               }
  //             });
  //           }
  //         }
  //       );
  //   }
  // }, [echo]);

  return (
    <Link
      href={URLConstants.home.pathname}
      className={`${isModalOpen ? "linkDisabled" : ""}`}
      onClick={(e) => handleClick(e, URLConstants.home.pathname, () => setScreenType('random'))}
      onMouseEnter={() => setIsHomeHovered(true)}
      onMouseLeave={() => setIsHomeHovered(false)}
    >
      {URLConstants.home.pathname === pathname ||
        (isHomeHovered && !isMobile) ? (
        <HomeActiveIcon />
      ) : (
        <HomeDeactiveIcon />
      )}
    </Link>
  );
};

export default HomeLink;
