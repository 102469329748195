import { User } from "next-auth";
import { URLConstants } from "@/constants/URLConstants";
import { useAppContext } from "@/context/AppContextProvider";
import { MouseEvent, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import UsersActiveIconLink from "@/components/icons/UsersActiveIconLink";
import UserActiveIcon from "@/components/icons/UserActiveIcon";
import UsersDeactiveIconLink from "@/components/icons/UsersDeactiveIconLink";
import UserDeactiveIcon from "@/components/icons/UserDeactiveIcon";
import Link from "next/link";


const isGroupPath = (path: string): boolean => {
  const groupPathRegex = /^\/group(\/.*)?$/; // "/group" または "/group/" 以降のパスも許容
  return groupPathRegex.test(path);
};


export const GroupFooterLink = ({ isModalOpen, user }: {
  isModalOpen: boolean;
  user: User | undefined
}) => {
  const { isMobile, } = useAppContext();
  const [isGroupHovered, setIsGroupHovered] = useState(false);
  const pathname = usePathname();
  const router = useRouter();

  const handleClick = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>, url: string) => {
    e.preventDefault();
    e.stopPropagation();

    router.push(url);
  };

  return (
    <div>
      <a
        href={URLConstants.group.pathname}
        className={`${isModalOpen ? "linkDisabled" : "cursor-pointer"}`}
        onMouseEnter={() => setIsGroupHovered(true)}
        onMouseLeave={() => setIsGroupHovered(false)}
      >
        {isGroupPath(pathname) ||
          (isGroupHovered && !isMobile) ? (
          user ? <UsersActiveIconLink user={user} /> : <UserActiveIcon />)
          : (user ? <UsersDeactiveIconLink user={user} /> : <UserDeactiveIcon />)
        }
      </a>
    </div>
  )
}