
export const isHeif = (file: File): boolean => {
	const fileType = file.type;
	return fileType === 'image/heic' || fileType === 'image/heif';
}

export const isHeic = (file: File): boolean => {
	const fileType = file.type;
	return fileType === 'image/heic' || fileType === 'image/heif';
}

export const checkFileMimeType = async (file: File): Promise<boolean> => {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			const arr = new Uint8Array(reader.result as ArrayBuffer).subarray(0, 4);
			const header = arr.reduce((acc, byte) => acc + byte.toString(16), "");

			// 画像のシグネチャ (マジックナンバー)
			const imageSignatures = ["89504e47", "ffd8ffe0", "ffd8ffe1", "ffd8ffe2", "47494638"];

			resolve(imageSignatures.some(sig => header.startsWith(sig)));
		};
		reader.readAsArrayBuffer(file);
	});
};
