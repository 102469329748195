
// base64 にエンコードする処理
export const encodeFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64Data = reader.result?.toString().split(",")[1]; // `data:image/png;base64,xxxxx` の `xxxxx` の部分
      if (base64Data) {
        resolve(base64Data);
      } else {
        reject(new Error("Base64 encoding failed."));
      }
    };
    reader.onerror = (error) => reject(error);
  });
}