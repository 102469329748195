"use client";

import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import ReactDOM from "react-dom";
import { useSession } from "next-auth/react";
import Location from "@/types/location";
import { Button } from "@/components/ui/button";
import Loading from "@/components/Loading";
import ImageIcon from "@/components/icons/ImageIcon";
import PlusActiveIcon from "../icons/PlusActiveIcon";
import PlusDivactiveIcon from "../icons/PlusDivactiveIcon";
import convertToUploadableImageFile from "@/services/image/image-service";
import { BrowserTypeChecker } from "@/lib/utils/browser/browser-utils";
import ScoreSlider from "./ScoreSlider";
import { useAppContext } from "@/context/AppContextProvider";
import CloseIcon from "@/components/icons/CloseIcon";
import { useTranslation } from "@/lib/react/i18n";
import { adjustImageOrientation } from "@/lib/file/image";
import { useImagePost } from "@/hooks/image/useImagePost";
import Image from "next/image";
import { useGroupExistsJoinMember } from "@/hooks/group/buttons/useGroupExistsJoinMember";
import { AxiosError } from "axios";
import { usePathname } from "next/navigation";
import { getPathParam } from "@/lib/utils";
import { encodeFileToBase64 } from "@/lib/utils/file";
import "@/components/pict/imageUploadModal.css";

const ImageUploadModal = () => {
  const { setIsUploading, isUploading } = useAppContext();
  const [file, setFile] = useState<File | null>(null);
  const [score, setScore] = useState<number | null>(0);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [location, setLocation] = useState<Location>({
    country: null,
    province: null,
    city: "EARTH",
    suburb: null,
    latitude: null,
    longitude: null,
  });

  const pathname = usePathname();
  const { isMobile, isModalOpen, setIsModalOpen } = useAppContext();
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { data: session } = useSession();
  const { submit } = useImagePost();
  const { t } = useTranslation();

  const user = session?.user;
  const [accept, setAccept] = useState("image/*");
  const groupId = getPathParam(pathname, "group/mypage/");
  const numericGroupId = isNaN(Number(groupId)) ? null : Number(groupId);

  // グループに参加してるかの判定
  const { data: groupExists, isError: groupExistsError, isLoading: groupExistsLoading } = useGroupExistsJoinMember({
    userId: user?.id ?? '',
    groupId: numericGroupId === null ? 0 : numericGroupId,
  })

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // ファイルのバリデーション。10MB未満でかつ画像ファイルのみに制限している
  const validateFile = async (selectedFile: File): Promise<boolean> => {
    const limitFileSize = 10 * 1024 * 1024;
    if (selectedFile.size > limitFileSize) {
      return false;
    }
    return true;
  };

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // 画像を選択する
  const handleImageSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMsg(null);
    setLoading(true);
    e.preventDefault();
    const selectedFile = e.target.files?.[0];

    if (!selectedFile) {
      setErrorMsg(t('Please select the image'));
      setLoading(false);

      return
    }
    if (selectedFile && (await validateFile(selectedFile))) {
      let convertedFile = selectedFile;
      try {
        // アップロード可能な形式に変換
        convertedFile = await convertToUploadableImageFile(convertedFile);
        // サムネイル表示可能な形式に変換
        const canvas = await adjustImageOrientation(convertedFile);

        setImagePreview(canvas.toDataURL("image/webp"));
        setFile(convertedFile);
        setErrorMsg(null);
        setLoading(false);
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          setErrorMsg(error.message);
        }
        setFile(convertedFile);
        setImagePreview(null);
        setLoading(false);
        return;
      }
    } else {
      setErrorMsg(t('Image size must be 10MB or less'));
      setLoading(false);
    }
  };

  const handleUpload = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsUploading(true);
    try {
      if (!file) {
        setErrorMsg("File not selected.");
        setIsUploading(false); // エラー時にアップロード中ステータスを解除
        closeModal();
        return;
      }

      if (user === undefined || !user.id) {
        setErrorMsg("user not found.");
        setIsUploading(false); // エラー時にアップロード中ステータスを解除
        closeModal();

        return;
      }

      closeModal();
      const validatedLocation = {
        latitude: location.latitude || 0,
        longitude: location.longitude || 0,
        country: location.country || "Unknown",
        province: location.province || "Unknown",
        city: location.city || "Unknown",
        suburb: location.suburb || "Unknown",
      };

      const encodeFile = await encodeFileToBase64(file);
      const res = await submit({
        ...validatedLocation,
        userId: user.id ?? "",
        score: score ?? 0,
        // グループマイページのみグループIDを付与してグループと紐づける
        groupId: numericGroupId,
        file: encodeFile,
        filename: file.name, // Laravel 側で元のファイル名を使えるようにする
        mimeType: file.type, // MIME タイプも送信
      });

      if (!res.ok) {
        setLoading(false);
        setErrorMsg(res.message);
        return;
      }

      /**
       * TODO: 修正が必要な箇所
       * 画像投稿後にリロード更新 遷移させない方向で調整
       */
      // if (typeof window !== "undefined") {
      //   // window.location.reload();
      // }
    } catch (e: any) {
      if (e instanceof Error) {
        console.error("Error uploading image:", e.message); // 詳細なエラーログを出力
        alert(e.message);
        setIsUploading(false);
        setErrorMsg(`Error: ${e.message}`);
      } else {
        console.error("Error uploading image:", e); // 詳細なエラーログを出力
        setErrorMsg(`Error: ${e}`);
        alert(e.message);
        setIsUploading(false);
      }
    }
    setFile(null);
    setScore(0);
    setImagePreview(null);
    setIsUploading(false);
  };

  useEffect(() => {
    const browserTypeChecker = new BrowserTypeChecker();
    if (navigator !== undefined && browserTypeChecker.isSafari()) {
      setAccept("image/*");
    } else {
      setAccept("image/*,image/heif, image/heic");
    }
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isModalOpen]);

  // 位置情報の取得
  useEffect(() => {
    // 位置情報がすでに許可されているかどうかをチェック
    const locationAllowed = localStorage.getItem("locationAllowed");

    // 位置情報を一度だけリクエスト
    if (!locationAllowed) {
      const getLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const fetchLocation = await fetch(
                `https://nominatim.openstreetmap.org/reverse?format=geojson&lat=${position.coords.latitude}&lon=${position.coords.longitude}`
              );
              const locationRes = await fetchLocation.json();
              const addressData = locationRes.features[0].properties.address;
              setLocation({
                country: addressData.country,
                province: addressData.province,
                city: addressData.city,
                suburb: addressData.suburb,
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              });

              // 位置情報の取得後、許可状態を記録
              localStorage.setItem("locationAllowed", "true");
            },
            (error) => {
              if (error instanceof AxiosError) {
                console.error("Validation errors:", error.response?.data.errors);
              }
            }
          );
        }
      };

      getLocation();
    }
  }, []);

  if (groupExistsError || groupExistsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {isUploading && (
        <div className="fixed inset-0 bg-black bg-opacity-20 flex items-center justify-center z-100">
          <Loading />
          <span className="text-white text-xl font-bold">now uploading…</span>
        </div>
      )}

      <button
        className="bg-transparent hover:bg-transparent"
        onClick={openModal}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isModalOpen || (isHovered && !isMobile) ? (
          <PlusActiveIcon className="w-8 h-8 plusActive" />
        ) : (
          <PlusDivactiveIcon className="w-8 h-8 plusDivActive" />
        )}
      </button>

      {isModalOpen &&
        ReactDOM.createPortal(
          <div
            className="fixed flex items-center justify-center modal-overlay"
            style={{ pointerEvents: "none", zIndex: 5000 }} // クリックを無効化
          >
            {/* 背景のクリックを防ぐ */}
            <div
              className="fixed inset-0 bg-black bg-opacity-20"
              onClick={closeModal} // 背景クリック時のみモーダルを閉じる
              style={{ pointerEvents: "auto" }} // 背景だけクリック可能にする
            ></div>

            {/* モーダル本体 */}
            <div
              className={`bg-gray-900 rounded-lg overflow-hidden transform transition-all duration-300 
        ${isModalOpen ? "translate-y-0 md:translate-y-0" : "-translate-y-full md:translate-y-full"}
        w-96 max-w-none h-auto md:h-auto max-h-[90vh]`}
              style={{ pointerEvents: "auto" }} // モーダル本体はクリック可能にする
              onClick={(e) => e.stopPropagation()} // モーダルクリック時に背景の `closeModal()` を防ぐ
            >
              {/* 閉じるボタン */}
              <button
                className="absolute top-2 right-2 text-gray-500"
                onClick={closeModal}
              >
                <CloseIcon className="w-6 h-6" />
              </button>

              {/* フォーム */}
              <form onSubmit={handleUpload} className="form">
                <div className="flex flex-col items-center justify-center space-y-4 p-4">
                  <label className="text-white pb-2">
                    {t("What is your self-evaluation score?")}
                  </label>
                  {errorMsg && <span className="text-red-600">{errorMsg}</span>}
                  <input
                    className="flex"
                    type="file"
                    accept={accept}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleImageSelect}
                  />
                  <div className="flex items-center space-x-2 bg-gray-800">
                    <Button
                      className="w-[350px] flex items-center space-x-2 bg-gray-800 hover:bg-black bg-transparent hover:bg-gradient-to-r hover:from-[#0000FF] hover:to-[#E140FF]"
                      variant="outline"
                      onClick={handleButtonClick}
                    >
                      <ImageIcon className="w-6 h-6" />
                      <span className="text-white">{t("Image Select")}</span>
                    </Button>
                  </div>
                  {imagePreview && (
                    <Image
                      src={imagePreview}
                      style={{
                        width: "auto",
                        height: 200,
                      }}
                      alt="preview"
                      width={300}
                      height={300}
                      priority={false}
                    />
                  )}
                  <ScoreSlider score={score} setScore={setScore} />
                  {loading && (
                    <div className="fixed inset-0 bg-black bg-opacity-20 flex items-center justify-center z-100">
                      <span className="text-white text-xl font-bold">
                        Loading...
                      </span>
                    </div>
                  )}
                </div>
                <div className="p-4">
                  <button className="border-gray-300 bg-gray-800 text-white py-2 px-4 mt-2 rounded bg-transparent hover:bg-gradient-to-r hover:from-[#0000FF] hover:to-[#E140FF] w-full">
                    {t("Upload")}
                  </button>
                </div>
              </form>
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default ImageUploadModal;
