"use client";

import { ImageSearchRequest } from "@/restapi/image";
import { useEffect, useState, useMemo } from "react"

export type TabItem = {
  label: string;
  value: FilterImageCategory;
  className?: string;
  clickTab?: () => void;
};

export const FILTER_IMAGE_CATEGORY_TYPE = {
  HIGHEST: "Highest",
  LOWHEST: "Lowest",
  NOT_SCORED: "NotScored",
  LATEST: "Latest",
  FOLLOWING: "Following",
  SPACE_ALL_GALLARY: "SpaceAllGallary",
} as const;

// 画像を抽出
export type FilterImageCategory = (typeof FILTER_IMAGE_CATEGORY_TYPE)[keyof typeof FILTER_IMAGE_CATEGORY_TYPE];

export const isValidFilterImageCategory = (value: any): value is FilterImageCategory => {
  return Object.values(FILTER_IMAGE_CATEGORY_TYPE).includes(value);
};

// 各ページで使用するときに指定する
export const PAGE_CATEGORY_KEYS = {
  MYPAGE: "mypage",
  RANKING: "ranking",
  HOME: "home",
} as const;

export type PageCategory = (typeof PAGE_CATEGORY_KEYS)[keyof typeof PAGE_CATEGORY_KEYS];

export type FilterImageCategoryState = {
  activeCategory: FilterImageCategory;
  request: ImageSearchRequest | null;
  handleCategoryChange: (selectedTab: FilterImageCategory) => void;
};

export type GalleryCategoryProps = {
  initFilterImageCategory: FilterImageCategory;
  filterByGroupId?: number | null;
  requestUserId?: string | null;
  filterByUserId?: string | null;
  pageCategory: PageCategory;
}

export const useGalleryCategory = ({
  initFilterImageCategory,
  requestUserId,
  filterByGroupId,
  filterByUserId,
  pageCategory,
}: GalleryCategoryProps): FilterImageCategoryState => {
  // console.log("🟢 useGalleryCategory: pageCategory =", pageCategory);

  const [activeCategory, setActiveCategory] = useState<FilterImageCategory>(initFilterImageCategory);

  const [request, setRequest] = useState<ImageSearchRequest | null>(
    {
      filterImageCategory: initFilterImageCategory,
      filterByGroupId,
      filterByUserId,
      pageCategory,
      requestUserId,
    }
  );

  const computedRequest: ImageSearchRequest | null = useMemo(() => {
    // console.log("🔵 useMemo: pageCategory =", pageCategory);
    return ({
      filterImageCategory: activeCategory,
      filterByUserId: filterByUserId ?? null,
      filterByGroupId: filterByGroupId ?? null,
      pageCategory,
      requestUserId,
    });
  }, [activeCategory, filterByUserId, filterByGroupId, requestUserId, pageCategory]);

  useEffect(() => {
    // console.log("useEffect: computedRequest =", computedRequest);
    setRequest(computedRequest);
  }, [computedRequest]);

  const handleCategoryChange = (selectedTab: FilterImageCategory) => {
    setActiveCategory(selectedTab);
  };

  return {
    activeCategory,
    request,
    handleCategoryChange,
  };
};