"use client";

import { MouseEvent, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import ImageUploadModal from "@/components/pict/ImageUploadModal";
import { useAppContext } from "@/context/AppContextProvider";

import CrownActiveIcon from "@/components//icons/CrownActiveIcon";
import CrownDeactiveIcon from "@/components/icons/CrownDeactiveIcon";
import PlusDivactiveIcon from "./icons/PlusDivactiveIcon";
import { URLConstants } from "@/constants/URLConstants";
import UserActivLink from "./mypage/UserActivLink";
import UserDeActiveLink from "./mypage/UserDeActiveLink";
import Link from "next/link";
import HomeLink from "./home/link/HomeLink";
import { GroupFooterLink } from "./group/links/GroupFooterLink";
import "@/components/footer.css";
import { useSession } from "next-auth/react";

const Footer = () => {
  const {
    setSettingMenuProps,
    visableMode,
    isMobile,
    isModalOpen,
    screenType,
    setScreenType,
  } = useAppContext();
  const [isImgHistoryHovered, setIsImgHistoryHovered] = useState(false);
  const [isCrownHovered, setIsCrownHovered] = useState(false);
  const pathname = usePathname();
  const { data: session } = useSession();
  const user = session?.user;

  const handleClick = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>, url: string, callback?: () => void,) => {
    e.preventDefault();
    e.stopPropagation();

    setSettingMenuProps({
      isMeneuOpen: false,
    });

    if (typeof window !== 'undefined') {
      window.location.href = url;
    }

  };

  return (
    <div
      className={`bg-black fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full min-w-screen-sm wrapFootNaviArea ${visableMode.isShow ? "fade-in" : "fade-out"
        } min-h-[60px]`}
      style={{ zIndex: 200 }}
    >
      <ul className="flex justify-around bg-opacity-50 py-4 z-50 footNaviArea">
        <li className="flex flex-col items-center footNavi footNavi01">
          <HomeLink />
        </li>
        <li className="flex flex-col items-center footNavi footNavi02">
          <a
            href={`${URLConstants.user.mypage.pathname}`}
            className={`${isModalOpen ? "linkDisabled" : "cursor-pointer"}`}
            onMouseEnter={() => setIsImgHistoryHovered(true)}
            onMouseLeave={() => setIsImgHistoryHovered(false)}
          >
            {URLConstants.user.mypage.pathname === pathname ||
              (isImgHistoryHovered && !isMobile) ? (
              <UserActivLink />
            ) : (
              <UserDeActiveLink />
            )}
          </a>
        </li>

        <li
          className="flex flex-col items-center footNavi footNavi03"
        >
          {user ? (
            <ImageUploadModal />
          ) : (
            <Link
              className={`${isModalOpen ? "linkDisabled" : "cursor-pointer"}`}
              href={URLConstants.accessDenied.pathname}
            >
              <PlusDivactiveIcon className="w-6 h-6" />
            </Link>
          )}
        </li>
        <li className="flex flex-col items-center footNavi footNavi04">
          <GroupFooterLink isModalOpen={isModalOpen} user={user} />
        </li>
        <li className="flex flex-col items-center  footNavi footNavi05">
          <Link
            onClick={(e) => handleClick(e, URLConstants.ranking.pathname, () => setScreenType('grid'))}
            href={URLConstants.ranking.pathname}
            className={`${isModalOpen ? "linkDisabled" : "cursor-pointer"}`}
            onMouseEnter={() => setIsCrownHovered(true)}
            onMouseLeave={() => setIsCrownHovered(false)}
          >
            {URLConstants.ranking.pathname === pathname ||
              (isCrownHovered && !isMobile) ? (
              <CrownActiveIcon />
            ) : (
              <CrownDeactiveIcon />
            )}
          </Link>
        </li>
      </ul>
    </div >
  );
};

export default Footer;
