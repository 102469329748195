import loadImage from "blueimp-load-image";

/**
 * 画像の向きを調整する
 */
export const adjustImageOrientation = (file: File): Promise<HTMLCanvasElement> => {
	return new Promise((resolve, reject) => {
		loadImage(file, (img: any) => {
			if (img.type === "error") {
				reject("Error loading image.");
			} else {
				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d");
				canvas.width = img.width;
				canvas.height = img.height;
				ctx?.drawImage(img as HTMLImageElement, 0, 0);
				resolve(canvas);
			}
		}, { orientation: true, canvas: true });
	});
};

// ファイルをData URLとして読み込む関数
export const readFileAsDataURL = (file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			if (reader.result) {
				resolve(reader.result as string);
			} else {
				reject(new Error("Failed to read file as Data URL"));
			}
		};
		reader.onerror = () => reject(new Error("File reading error"));
		reader.readAsDataURL(file);
	});
};

// 画像をロードする関数
export const loadHtmlImageElement = (src: string): Promise<HTMLImageElement> => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => resolve(img);
		img.onerror = () => reject(new Error("Failed to load image"));
		img.src = src;
	});
};

export async function removeMetadata(file: File): Promise<File> {
	// 画像ファイルの読み込み
	const dataURL = await readFileAsDataURL(file);
	const img = await loadHtmlImageElement(dataURL);

	// Canvasに描画してメタデータを除去
	const canvas = document.createElement('canvas');
	canvas.width = img.width;
	canvas.height = img.height;
	const ctx = canvas.getContext('2d');
	if (!ctx) throw new Error('2D context not available');

	ctx.drawImage(img, 0, 0);

	// Canvasの内容をBlobとして取得
	const blob = await new Promise<Blob>((resolve, reject) => {
		canvas.toBlob((blob) => {
			if (blob) {
				resolve(blob);
			} else {
				reject(new Error("Blob creation failed"));
			}
		}, 'image/jpeg');
	});

	// BlobをFileに変換
	const newFile = new File([blob], file.name, { type: file.type });

	return newFile;
}


/**
 * 画像情報
 */
type ImageFileInfo = {
	width: number;
	height: number;
}

/**
 * 画像の解像度を取得する
 * @param file 
 * @returns 
 */
export const getImageResolution = async (file: File): Promise<ImageFileInfo> => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.src = URL.createObjectURL(file);

		img.onload = () => {
			resolve({ width: img.width, height: img.height });
		};

		img.onerror = (error) => {
			reject(error);
		};
	});
}
